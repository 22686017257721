import React from "react";
import { ThemeProvider, CssBaseline, Box, Typography } from "@mui/material";

import Footer from "./components/Footer";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" gutterBottom>
            株式会社エアダス
          </Typography>
          <img src="/images/airdas_logo.png" alt="logo" />
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
